import React, { useState, createContext } from "react";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [sidebarState, setSidebarState_] = useState(false);
  const setSidebarStateOpen = () => setSidebarState_(true);
  const setSidebarStateClose = () => setSidebarState_(false);
  
  const sidebarOptionInitialState = {
    english: {
      state: false,
    },
    math: {
      state: false,
    },
    physics: {
      state: false,
    },
    chemistry: {
      state: false,
    },
  }
  
  const [sidebarOptionState, setSidebarOptionState] = useState(sidebarOptionInitialState);

  return (
    <SidebarContext.Provider value={{
      sidebarState, 
      setSidebarStateOpen,
      setSidebarStateClose, 
      sidebarOptionState, 
      setSidebarOptionState 
    }}>
      { children }
    </SidebarContext.Provider>
  )
}

export default SidebarContext;