import React, { useEffect, useContext } from "react";
import SiC from "../../icon/SiC";
import SiDjango from "../../icon/SiDjango";
import SiDocker from "../../icon/SiDocker";
import SiMysql from "../../icon/SiMysql";
import SiNginx from "../../icon/SiNginx";
import SiNpm from "../../icon/SiNpm";
import SiWebpack from "../../icon/SiWebpack";

import classNames from "classnames";

import { MyIcon, SidebarButton, SidebarButton1, SidebarButton2, SidebarButtonLower } from "../../components";
import { SidebarContext, MobileSidebarContext } from "../../context";
import "./style.scss";

export default function Sidebar() {
  const { sidebarState, setSidebarStateOpen, setSidebarStateClose } = useContext(SidebarContext);
  const { mobileSidebarState, setMobileSidebarStateOpen, setMobileSidebarStateClose } = useContext(MobileSidebarContext);

  function toggleHamburger() {
    if (mobileSidebarState) {
      setMobileSidebarStateClose();
    }
    else {
      setMobileSidebarStateOpen();
    }
  }

  return (
    <>
      <div
        className={classNames("sidebar", { sidebar_active: sidebarState }, { mobile_sidebar_active: mobileSidebarState})}
        onMouseEnter={setSidebarStateOpen}
        onMouseLeave={setSidebarStateClose}
      >
        <div className="sidebar__upper">
          <div className={classNames("mobile_navbar")}>
            <MyIcon />
            <button onClick={ toggleHamburger } className="hamburger">
              <i className={classNames("fas fa-bars", { hamburger_state: !mobileSidebarState})}></i>
              <i className={classNames("fas fa-times", { hamburger_state: mobileSidebarState})}></i>
            </button>
          </div>
          <div className="mobile_navbar_content">
            <SidebarButton icon="fas fa-home-lg" title="Home" to="/"/>
  
            <SidebarButton icon="fad fa-school" title="Academic Notes" to="/academic"/>
            <SidebarButton1 icon="fad fa-font-case" title="English" to="/academic/english" option="english.state"/>
            <SidebarButton2 title="Vocabulary" to="/academic/english/vocabulary" pOption="english.state"/>
            <SidebarButton2 title="Grammar" to="/academic/english/grammar" pOption="english.state"/>
            <SidebarButton2 title="Articles" to="/academic/english/articles" pOption="english.state"/>
  
            <SidebarButton1 icon="fad fa-function" title="Math" to="/academic/math" option="math.state"/>
            <SidebarButton2 title="L1 多項式函數 筆記 L" to="/academic/math/01L" pOption="math.state"/>
            <SidebarButton2 title="L1 多項式函數 難題 T" to="/academic/math/01T" pOption="math.state"/>
  
            <SidebarButton1 icon="fad fa-atom" title="Physics" to="/academic/physics" option="physics.state"/>
            <SidebarButton2 title="L1 運動學 筆記 L" to="/academic/physics/01L" pOption="physics.state"/>
            <SidebarButton2 title="L1 運動學 難題 T" to="/academic/physics/01T" pOption="physics.state"/>
  
            <SidebarButton1 icon="fad fa-flask" title="Chemistry" to="/academic/chemistry" option="chemistry.state"/>
  
            <SidebarButton icon="fad fa-code" title="Coding Notes" to="/coding"/>
            <SidebarButton1 icon={ <SiC /> } title="C" to="/coding/c" starCount={3}/>
            <SidebarButton1 icon="fab fa-python" title="Python" to="/coding/python" starCount={3}/>
            <SidebarButton1 icon="fab fa-js" title="JavaScript" to="/coding/javascript" starCount={3}/>
            <SidebarButton1 icon="fab fa-html5" title="HTML" to="/coding/html" starCount={3}/>
            <SidebarButton1 icon="fab fa-css3" title="CSS" to="/coding/css" starCount={3}/>
            <SidebarButton1 icon="fab fa-react" title="React" to="/coding/react" starCount={2.5}/>
            <SidebarButton1 icon={ <SiWebpack /> } title="Webpack" to="/coding/webpack" starCount={2.5}/>
            <SidebarButton1 icon={ <SiDjango /> } title="Django" to="/coding/django" starCount={2}/>
            <SidebarButton1 icon="fab fa-git-alt" title="Github" to="/coding/github" starCount={2}/>
            <SidebarButton1 icon={ <SiNpm /> } title="NPM" to="/coding/npm" starCount={2}/>
            <SidebarButton1 icon={ <SiNginx /> } title="Nginx" to="/coding/nginx" starCount={2}/>
            <SidebarButton1 icon="fad fa-terminal" title="Terminal" to="/coding/terminal" starCount={1.5}/>
            <SidebarButton1 icon={ <SiMysql /> } title="MySQL" to="/coding/mysql" starCount={1.5}/>
            <SidebarButton1 icon={ <SiDocker /> } title="Docker" to="/coding/docker" starCount={1}/>
  
            <SidebarButton icon="fad fa-folders" title="My Project" to="/project"/>
            <SidebarButton1 icon="fad fa-books" title="English Reading Corner" to="/project/enreading"/>
          </div>
        </div>
      </div>
      <SidebarButtonLower />
    </>
  );
}