import React from "react";
import { Link } from "react-router-dom";

import "./style.scss";

export default function Home() {
  return (
    <div className="home_container">
      <div className="home">
        <header className="home__title">
          <h1 className="home__title_txt">
            Roger Fan's Learning Journey
            <div className="color"></div>
          </h1>
        </header>
        <section className="home__content">
          <div className="home__content_intro">
            <h2>簡介</h2>
            <p>
              歡迎照訪此網站，我是 <span className="hl-p"><b>范升維 Roger Fan</b></span>，目前就讀武陵高中三年級，此網站是用以分享我在高中時期課外學習的<span className="hl-g">程式學習筆記</span>和課內學習的<span className="hl-g">學業統整筆記</span>。
            </p>
            <p>
              創建這個網站的動機是由於在升上高一後開始思考未來的方向時，接觸到程式語言，簡單探索了一下便發現寫網頁是相較於其他 C, C++, Java, Python...等語言更加直接能看到成果的類別，因此就開始HTML, CSS等的學習<span className="short">(詳見學習歷程-彈性學習時間)</span>，後來發現利用網頁不論是將其他程式語言的學習<span className="short">(詳見學習歷程-彈性學習時間)</span>抑或是課內學習成果記錄下來，都是不錯的方式，因此便在高二下(學習完C,Python,HTML,CSS,JS,React...後)開始著手建造此網頁！
            </p>
          </div>
          <div className="home__content_tech">
            <h2>
              使用之技術
            </h2>
            <p>從<span className="hl-y">自架伺服器</span>、<span className="hl-y">網域購買</span>到<span className="hl-y">網頁架設</span>、<span className="hl-y">資安設計</span>全為「自學自架」，無報名課程也無購買其他線上服務。</p>
            <ul>
              <li>前端：HTML5、CSS3、JS6、React.JS、Webpack...</li>
              <li>後端：JS6、Express.JS、MongoDB、NGINX、JWT、電子郵件寄送API、Google API、PM2(流程管理器)</li>
              <li>輔助：NPM、GitHub、Bash</li>
            </ul>
            <p>自行建構「註冊及登入系統」、「權限系統」、「電子郵件寄送系統」。
            </p>
          </div>
          <div className="home__content_service">
            <h2>
              提供之服務
            </h2>
            <p>
              各式程式語言及npm、GitHub等的詳細筆記內容及課業知識的筆記內容，皆可由左側索引進入檢視。程式相關學習筆記的完整度及精熟度以半顆星至三顆星分級表示，而部分課業筆記分為重點筆記和難題筆記。以上所述的筆記內容，請<span className="hl-g"><Link to="./login">登入</Link>以檢閱</span>。
            </p>
          </div>
        </section>
        <footer className="home__social">
          <div className="home__social_email h-icon">
            <a href="mailto:rogerfantw@gmail.com">
              <i className="fas fa-envelope"></i>
            </a>
          </div>
          <div className="home__social_github h-icon">
            <a href="https://github.com/rogerfantw">
              <i className="fab fa-github-square"></i>
            </a>
          </div>
          <div className="home__social_ig h-icon">
            <a href="https://www.instagram.com/rogerfan48/">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </footer>
      </div>
    </div>
  )
}