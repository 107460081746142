import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

import { SidebarContext, MobileSidebarContext } from "../../context";
import { GDP, SDP } from "../../helper";
import "./style.scss";

export default function SidebarButton2({title, to, pOption, option=''}) {
  const { sidebarState, sidebarOptionState, setSidebarOptionState } = useContext(SidebarContext);
  const { mobileSidebarState } = useContext(MobileSidebarContext);

  let optionUse;
  if (option === '') {
    optionUse = false;
  } else {
    optionUse = true;
  }
  let tmp;
  function changeOptionState(e) {
    e.preventDefault();
    tmp = sidebarOptionState;
    SDP(tmp, option, !GDP(sidebarOptionState, option));
    setSidebarOptionState({...tmp});
  }
  let nowState = GDP(sidebarOptionState, pOption);

  return (
    <NavLink to={ to } end className={({ isActive }) => isActive ? "nav_now_on" : undefined}>
      <div className={ classNames("sidebar2__divider", {sidebar_active: sidebarState}, { mobile_sidebar_active: mobileSidebarState }, {sidebar_opt_hide: !nowState}) }></div>
      <div className={ classNames("sidebar2__topic", {sidebar_active: sidebarState}, { mobile_sidebar_active: mobileSidebarState }, {sidebar_opt_hide: !nowState}) }>
        <span className="sidebar2__topic__title">- { title }</span>
        <div 
          className={ classNames("sidebar2__topic__option", {option_use: optionUse}) }
          onClick={changeOptionState}
        >
          <i className={ classNames("option far fa-angle-down", {option_open: GDP(sidebarOptionState, option)}) }></i>
        </div>
      </div>
    </NavLink>
  )
}