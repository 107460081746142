import axios from "../api/axios";
import useAuth from './useAuth';
import jwt_decode from "jwt-decode";

const useFirstRefresh = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get('/refresh', {
      withCredentials: true,
    });
    setAuth(prev => {
      const accessToken = response?.data?.accessToken;

      if (!accessToken) return {};
      const userData = jwt_decode(accessToken).userInfo;
      const userId = userData.userId;
      const email = userData.email;
      const roles = userData.roles;
      return { userId, email, roles}
    });
  }

  return refresh;
}

export default useFirstRefresh;