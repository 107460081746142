import React from "react";

import "./style.scss";

export default function Enreading() {
  return (
    <div className="enreading">
      <iframe src="https://roger.tw/enreading/home"></iframe>
    </div>
  )
}