import React from "react";

import "./style.scss";

export default function Coding() {
  return (
    <div className="coding">
      Coding
    </div>
  )
}