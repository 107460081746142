import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from '../../../api/axios';
import { REGISTER_URL } from "../../../api/endpoint";
import { ID_REGEX, EMAIL_REGEX, PWD_REGEX } from "../../../helper/authPattern.js";
import "./style.scss";

export default function Register() {
  const navigate = useNavigate();

  const idRef = useRef();
  const errRef = useRef();

  const [id, setId] = useState("");
  const [validId, setValidId] = useState(false);
  const [idFocus, setIdFocus] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    // idRef.current.focus();
  }, []);

  useEffect(() => {
    if (id === "") {
      setValidId(true);
    } else {
      const result = ID_REGEX.test(id);
      setValidId(result);
    }
  }, [id]);

  useEffect(() => {
    const result = EMAIL_REGEX.test(email);
    setValidEmail(result);
  }, [email]);

  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg('');
  }, [id, email, pwd, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if button enabled with JS hack
    const v1 = id === "" ? true : ID_REGEX.test(id);
    const v2 = EMAIL_REGEX.test(email);
    const v3 = PWD_REGEX.test(pwd);
    if (!v1 || !v2 || !v3) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axios.post(REGISTER_URL,
        { userId: id, email: email, password: pwd },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      navigate('/login', { replace: true });
    } catch (err) {
      if (!err.response?.data) {
        setErrMsg("後端伺服器未運作，請聯繫我或稍後再試");
      } else {
        switch (err.response.data?.state) {
          case 1:
            setErrMsg("無Email或Password");
            break;
          case 2:
            setErrMsg("該Email已被註冊");
            break;
          case 3:
            setErrMsg("該User Id已被註冊");
            break;
          case 4:
            setErrMsg("User Id格式錯誤");
            break;
          case 5:
            setErrMsg("Email格式錯誤");
            break;
          case 6:
            setErrMsg("Password格式錯誤");
            break;
          case 7:
            setErrMsg("Server端錯誤，請稍後再試");
            break;
          default:
            setErrMsg("未預期錯誤，請稍後再試");
        }
      }
      errRef.current.focus();
    }
  }

  return (
    <div className="register_container">
      <section className="register">
        <p ref={errRef} className={errMsg ? "err_msg" : "offscreen"}>{errMsg}</p>
        <h1>註冊 Sign Up</h1>
        <form onSubmit={handleSubmit}>
          <div className="txt_field">
            <input 
              type="text"
              id="userId"
              ref={idRef}
              placeholder="可選填"
              autoComplete="off"
              onChange={(e) => setId(e.target.value)}
              onFocus={() => setIdFocus(true)}
              onBlur={() => setIdFocus(false)}
            />
            <span></span>
            <label htmlFor="userId">
              User Id:
              <span className={validId ? "valid" : "hide"}>
                <i className="fas fa-check-circle"></i>
              </span>
              <span className={validId || !id ? "hide" : "invalid"}>
                <i className="fas fa-times-circle"></i>
              </span>
            </label>
          </div>
          <div id="idNote" className={idFocus && !validId ? "instructions" : "offscreen"}>
            <div className="info_icon">
              <i className="fad fa-info-circle"></i>
            </div>
            <p className="info_text">
              可選填4~24字元，若不指定則自動生成<br />
              開頭需為「英文字母」，但不可以「user」開頭<br />
              其餘可為「英文字母、數字、底線」
            </p>
          </div>
  
          <div className="txt_field">
            <input 
              type="text"
              id="email"
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
              required
              onFocus={() => setEmailFocus(true)}
              onBlur={() => setEmailFocus(false)}
            />
            <span></span>
            <label htmlFor="email">
              Email:
              <span className={validEmail ? "valid" : "hide"}>
                <i className="fas fa-check-circle"></i>
              </span>
              <span className={validEmail || !email ? "hide" : "invalid"}>
                <i className="fas fa-times-circle"></i>
              </span>
            </label>
          </div>
          <div id="emailNote" className={emailFocus ? "instructions" : "offscreen"}>
            <div className="info_icon">
              <i className="fad fa-info-circle"></i>
            </div>
            <p className="info_text">
              請輸入真實email
            </p>
          </div>
  
          <div className="txt_field">
            <input
              type="password"
              id="password"
              onChange={(e) => setPwd(e.target.value)}
              required
              onFocus={() => setPwdFocus(true)}
              onBlur={() => setPwdFocus(false)}
            />
            <span></span>
            <label htmlFor="password">
              Password:
              <span className={validPwd ? "valid" : "hide"}>
                <i className="fas fa-check-circle"></i>
              </span>
              <span className={validPwd || !pwd ? "hide" : "invalid"}>
                <i className="fas fa-times-circle"></i>
              </span>
            </label>
          </div>
          <div id="pwdNote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
            <div className="info_icon">
              <i className="fad fa-info-circle"></i>
            </div>
            <p className="info_text">
              8~24字元<br />
              只少一個英文字元
            </p>
          </div>
  
          <div className="txt_field">
            <input
              type="password"
              id="confirm_pwd"
              onChange={(e) => setMatchPwd(e.target.value)}
              required
              onFocus={() => setMatchFocus(true)}
              onBlur={() => setMatchFocus(false)}
            />
            <span></span>
            <label htmlFor="confirm_pwd">
              Confirm Password:
              <span className={validMatch && matchPwd ? "valid" : "hide"}>
                <i className="fas fa-check-circle"></i>
              </span>
              <span className={validMatch || !matchPwd ? "hide" : "invalid"}>
                <i className="fas fa-times-circle"></i>
              </span>
            </label>
          </div>
          <div id="confirmNote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
            <div className="info_icon">
              <i className="fad fa-info-circle"></i>
            </div>
            <p className="info_text">
              必須和password相同
            </p>
          </div>
  
          <button disabled={!(validId && validEmail && validPwd && validMatch)}>Sign Up</button>
        </form>
        <p className="register_goLogin">
          已註冊? 點此前往 <span className="to_login_btn"><Link to="/login">登入</Link></span>
        </p>
      </section>
    </div>
  )
}