import React from "react";

import "./style.scss";

export default function PageNotFound() {
  return (
    <div className="page_not_found_container">
      <div className="page_not_found">
        <h1>
          PageNotFound
        </h1>
      </div>
    </div>
  )
}