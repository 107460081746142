import React from "react";

import "./style.scss";

export default function Project() {
  return (
    <div className="project">
      Project
    </div>
  )
}