import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import { Home, Academic, Coding, Project, Enreading, User, Login, LoginFirst, Register, PageNotFound } from "./pages";
import { Sidebar } from "./layout";
import { SidebarProvider, MobileSidebarProvider } from "./context";
import "./style.scss";

import { useState, useEffect } from 'react';
import useFirstRefresh from "./auth/useFirstRefresh";
import useAuth from "./auth/useAuth";

export default function App () {
  const navigate = useNavigate();
  const location = useLocation();
  const refresh = useFirstRefresh();
  const { auth, persist } = useAuth();
  let onlyOnce = true

  // persist Login
  useEffect(() => {
    if (persist) {
      const verifyRefreshToken = async () => {
        try {
          await refresh();
        } catch (err) {
          switch (err?.response?.data?.state) {
            case 2:
            case 3:
              navigate('/login', { state: { from: location, info: "登入逾時，請重新登入以策安全" }, replace: true });
              break;
            case 4:
              navigate('/login', { state: { from: location, info: "認證失敗，請重新登入" }, replace: true });
          }
        }
      }
      if (!auth?.accessToken && onlyOnce) {
        onlyOnce = false;
        verifyRefreshToken()
      }
    }
  }, [])

  return (
    <main className="App">
      <SidebarProvider>
        <MobileSidebarProvider>
          <Sidebar />
        </MobileSidebarProvider>
      </SidebarProvider>
      <div className="main_app">
        <Routes>
          <Route path="/" element={ <Home /> } caseSensitive={false}></Route>
          <Route path="academic" element={ <Academic /> } caseSensitive={false}></Route>
          <Route path="coding" element={ <Coding /> } caseSensitive={false}></Route>
          <Route path="project" element={ <Project /> } caseSensitive={false}></Route>
          <Route path="project/enreading" element={ <Enreading /> } caseSensitive={false}></Route>
          <Route path="user" element={ <User /> } caseSensitive={false}></Route>
          <Route path="login" element={ <Login /> } caseSensitive={false}></Route>
          <Route path="login/first" element={ <LoginFirst /> } caseSensitive={false}></Route>
          <Route path="register" element={ <Register /> } caseSensitive={false}></Route>
          <Route path="*" element={ <PageNotFound /> } caseSensitive={false}></Route>
        </Routes>
      </div>
    </main>
  )
}