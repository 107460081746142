import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { LoadingPage } from "../../components";
import useAuth from "../../auth/useAuth";
import "./style.scss";

export default function User() {
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false)
    }, 500);
  }, [])

  return (
    <>
      {
        isLoading
          ? <LoadingPage />
          : <UserContents />
      }
    </>
  )
}

function UserContents () {
  const { auth, setAuth } = useAuth();

  return (
    <div className="user_container">
      <section className="user">
        { Object.keys(auth).length === 0 && <Navigate to="/login"/> }
        <div className="user_info">
          <div>{JSON.stringify(auth)}</div>
        </div>
      </section>
    </div>
  )
}