import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

import { SidebarContext, MobileSidebarContext } from "../../context";
import "./style.scss";

export default function SidebarButton({icon, title, to}) {
  const { sidebarState } = useContext(SidebarContext);
  const { mobileSidebarState } = useContext(MobileSidebarContext);
  return (
    <NavLink to={ to } end className={({ isActive }) => isActive ? "nav_now_on" : undefined}>
      <div className={ classNames("sidebar__divider", {sidebar_active: sidebarState}, { mobile_sidebar_active: mobileSidebarState }) }></div>
      <div className={ classNames("sidebar__topic", {sidebar_active: sidebarState}, { mobile_sidebar_active: mobileSidebarState }) }>
        <div className="sidebar__topic__icon">
          <i className={ icon }></i>
        </div>
        <span className="sidebar__topic__title"> { title }</span>
      </div>
    </NavLink>
  )
}