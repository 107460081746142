import React from "react";

import "./style.scss";

export default function Academic() {
  return (
    <div className="academic">
      Academic測試測試字型
    </div>
  )
}