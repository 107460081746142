import React, { useContext } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { AuthContext, SidebarContext } from "../../context";
import useLogout from "../../auth/useLogout";
import "./style.scss";

export default function SidebarButtonLower() {
  const { sidebarState, setSidebarStateOpen, setSidebarStateClose } = useContext(SidebarContext);
  const { auth } = useContext(AuthContext);
  const logout = useLogout();
  return (
    <div 
      className={ classNames("sidebar__user__container", {sidebar_active: sidebarState}, {sidebar_authed: !(Object.keys(auth).length === 0)}) }
      onMouseEnter={setSidebarStateOpen}
      onMouseLeave={setSidebarStateClose}
    >
      { Object.keys(auth).length === 0
      ?
        <div className={ classNames("sidebar__user", {sidebar_active: sidebarState}) }>
          <Link to="/login">
            <div className="sidebar__user__dyn">
              <div className="sidebar__user__dyn__icon">
                <i className="fad fa-sign-in"></i>
              </div>
              <span className="sidebar__user__dyn__title">登入 Sign in</span>
            </div>
          </Link>
          <Link to="/register">
            <div className="sidebar__user__dyn">
              <div className="sidebar__user__dyn__icon">
                <i className="fad fa-user-plus"></i>
              </div>
              <span className="sidebar__user__dyn__title">註冊 Sign up</span>
            </div>
          </Link>
        </div>
      : 
        <div className={ classNames("sidebar__user", {sidebar_active: sidebarState}) }>
          <Link to="/user">
            <div className="sidebar__user__dyn">
              <div className="sidebar__user__dyn__icon">
              <i className="fas fa-user-circle"></i>
              </div>
              <span className="sidebar__user__dyn__title">Account</span>
            </div>
          </Link>
          <div onClick={logout} className="sidebar__user__dyn sidebar__logout">
            <div className="sidebar__user__dyn__icon">
              <i className="fad fa-sign-out"></i>
            </div>
            <span className="sidebar__user__dyn__title">登出 Sign out</span>
          </div>
        </div>
      }
    </div>
  )
}