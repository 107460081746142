import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

import { SidebarContext, MobileSidebarContext } from "../../context";
import { GDP, SDP } from "../../helper";
import "./style.scss";

export default function SidebarButton1({icon, title, to, starCount=0, option=''}) {
  const { sidebarState, sidebarOptionState, setSidebarOptionState } = useContext(SidebarContext);
  const { mobileSidebarState } = useContext(MobileSidebarContext);

  function classOf(o) {
    return Object.prototype.toString.call(o).slice(8,-1);
  }
  const x = (classOf(icon) === 'String') ? true : false;

  let starUse = true;
  let starContent = [];
  switch (starCount) {
    case 0: starUse = false; break;
    case 1: starContent = ["fas fa-star", "far fa-star", "far fa-star"]; break;
    case 1.5: starContent = ["fas fa-star", "fas fa-star-half-alt", "far fa-star"]; break;
    case 2: starContent = ["fas fa-star", "fas fa-star", "far fa-star"]; break;
    case 2.5: starContent = ["fas fa-star", "fas fa-star", "fas fa-star-half-alt"]; break;
    case 3: starContent = ["fas fa-star", "fas fa-star", "fas fa-star"]; break;
  }

  let optionUse;
  if (option === '') {
    optionUse = false;
  } else {
    optionUse = true;
  }
  let tmp;
  function changeOptionState(e) {
    e.preventDefault();
    tmp = sidebarOptionState;
    SDP(tmp, option, !GDP(sidebarOptionState, option));
    setSidebarOptionState({...tmp});
  }

  return (
    <NavLink to={ to } end className={({ isActive }) => isActive ? "nav_now_on" : undefined}>
      <div className={ classNames("sidebar1__divider", {sidebar_active: sidebarState}, { mobile_sidebar_active: mobileSidebarState }) }></div>
      <div className={ classNames("sidebar1__topic", {sidebar_active: sidebarState}, { mobile_sidebar_active: mobileSidebarState }) }>
        <div className="sidebar1__topic__icon">
          {x && <i className={ icon }></i>}
          {!x && icon}
        </div>
        <span className="sidebar1__topic__title"> { title }</span>
        <div className={ classNames("sidebar1__topic__star", {star_not_use: !starUse}) }>
          <i className={ classNames("star", starContent[0]) }></i>
          <i className={ classNames("star", starContent[1]) }></i>
          <i className={ classNames("star", starContent[2]) }></i>
        </div>
        <div 
          className={ classNames("sidebar1__topic__option", {option_use: optionUse}) }
          onClick={changeOptionState}
        >
          <i className={ classNames("option far fa-angle-down", {option_open: GDP(sidebarOptionState, option)}) }></i>
        </div>
      </div>
    </NavLink>
  )
}