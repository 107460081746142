import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import jwt_decode from 'jwt-decode';

import axios from "../../../api/axios";
import { LOGIN_URL } from "../../../api/endpoint";
import useAuth from "../../../auth/useAuth";
import { ID_REGEX, EMAIL_REGEX } from "../../../helper/authPattern.js";
import "./style.scss";

export default function Login() {
  const { auth, setAuth, persist, setPersist } = useAuth();
  
  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from?.pathname || "/user";

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd])

  useEffect(() => {
    if (location?.state?.info) {
      setErrMsg(location.state.info);
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let authWith;
      if (ID_REGEX.test(user)) {
        authWith = "userId";
      } else if (EMAIL_REGEX.test(user)) {
        authWith = "email";
      } else {
        new Error("WRONG FORMAT");
      }
      const response = await axios.post(LOGIN_URL,
        { [authWith]: user, password: pwd },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      const accessToken = response?.data?.accessToken;
      const userData = jwt_decode(accessToken).userInfo;
      const userId = userData.userId;
      const email = userData.email;
      const roles = userData.roles;
      setAuth({ userId, email, roles});
      
      setUser('');
      setPwd('');
      navigate(from, { replace: true });
    } catch (err) {
      if (err.message === "WRONG FORMAT") {
        setErrMsg("請輸入正確User Id或Email");
      } else if (!err.response?.data) {
        setErrMsg("後端伺服器未運作，請聯繫我(rogerfantw@gmail.com)或稍後再試");
      } else {
        switch (err.response.data?.state) {
          case 1:
            setErrMsg("請輸入正確User Id或Email");
            break;
          case 2:
            setErrMsg("查無此User，請前往註冊頁面");
            break;
          case 3:
            setErrMsg("密碼錯誤");
            break;
          case 4:
            setErrMsg("Email尚未開通，請前往電子信箱查看");
            break;
          default:
            setErrMsg("未預期錯誤，請稍後再試");
        }
      }
      errRef.current.focus();
    }
  }

  const togglePersist = () => {
    setPersist(prev => !prev);
  }

  useEffect(() => {
    localStorage.setItem("persist", persist)
  }, [persist])

  return (
    <div className="login_container">
      <section className="login">
        { Object.keys(auth).length !== 0 && <Navigate to="/user"/> }
        <p ref={errRef} className={errMsg ? "err_msg" : "offscreen"}>{errMsg}</p>
        <h1>登入 Sign In</h1>
        <form onSubmit={handleSubmit}>
          <div className="txt_field">
            <input
              type="text"
              id="user"
              required
              ref={userRef}
              autoComplete="off"
              onChange={(e) => setUser(e.target.value)}
              value={user}
            />
            <span></span>
            <label htmlFor="user">User Id 或 Email</label>
          </div>
  
          <div className="txt_field">
            <input
              type="password"
              id="password"
              required
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
            />
            <span></span>
            <label htmlFor="password">Password</label>
          </div>

          <div className="allow_persist">
            <input 
              type="checkbox"
              id="persist"
              onChange={togglePersist}
              checked={persist}
            />
            <label htmlFor="persist"><p>是否信任此裝置（公用設備，請勿勾選）<br />若不信任，在重載頁面時自動登出</p></label>
          </div>
  
          <button>Sign In</button>
        </form>
        <p className="login_goRegister">
          未註冊? 點此前往 <span className="to_login_btn"><Link to="/register">註冊</Link></span>
        </p>
      </section>
    </div>
  )
}