import React, { useState, createContext } from "react";

const MobileSidebarContext = createContext();

export const MobileSidebarProvider = ({ children }) => {
  const [mobileSidebarState, setMobileSidebarState_] = useState(false);
  const setMobileSidebarStateOpen = () => setMobileSidebarState_(true);
  const setMobileSidebarStateClose = () => setMobileSidebarState_(false);

  return (
    <MobileSidebarContext.Provider value={{
      mobileSidebarState, 
      setMobileSidebarStateOpen,
      setMobileSidebarStateClose, 
    }}>
      { children }
    </MobileSidebarContext.Provider>
  )
}

export default MobileSidebarContext;