import Home from "./Home/Home";
import Academic from "./Academic/Academic";
import Coding from "./Coding/Coding";
import Project from "./Project/Project";
import Enreading from "./Project/Enreading/Enreading";
import User from "./User/User";
import Login from "./User/Login/Login";
import LoginFirst from "./User/LoginFirst/LoginFirst";
import Register from "./User/Register/Register";
import PageNotFound from "./PageNotFound/PageNotFound";

export { Home, Academic, Coding, Project, Enreading, User, Login, LoginFirst, Register, PageNotFound };